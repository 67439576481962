











































































































































import {
  ListPageFilters,
  PatientStatusFilter,
  MultiSelect,
  InlineLoader,
  CheckboxInput,
} from '@/shared';
import sink from '@/sink';
import { computed, defineComponent } from '@vue/composition-api';
import {
  PatientsFilterChanged,
  PatientsFiltersCleared,
  PatientsFilterSearched,
  PatientsFiltersRestored,
} from './+state/events';
import { PatientsFilters } from './models';
import DatePicker from '@/shared/components/DatePicker.vue';

export default defineComponent({
  components: {
    CheckboxInput,
    ListPageFilters,
    PatientStatusFilter,
    InlineLoader,
    MultiSelect,
    DatePicker,
  },
  setup() {
    const filters = sink.select('patients.filters');
    sink.broadcast(PatientsFiltersRestored());

    return {
      filters,
      loadingPatients: sink.select('patients.loading'),
      loadingProviders: sink.select('providers.loading'),
      providers: sink.select('providers.select-options'),
      languages: sink.select('capabilities.languages'),
      loadingCapabilities: sink.select('capabilities.loading'),
      caregiverEnabled: sink.select('capabilities.features.caregiver'),

      isDateRangeValid: computed(() => {
        if (filters.value.createdAtFrom && filters.value.createdAtTo) {
          return (
            new Date(filters.value.createdAtTo) >=
              new Date(filters.value.createdAtFrom) ||
            'Created From must be on/before Created To'
          );
        }
        return true;
      }),
      hasMultipleLanguages: sink.select(
        'capabilities.enabledLanguages.hasMultiple',
      ),
      languageItems: sink.select('capabilities.enabledLanguages.selectOptions'),
      onSearch: sink.lazyBroadcast(
        PatientsFilterSearched({ origin: 'search', resetPage: true }),
      ),
      onClearAll: sink.lazyBroadcast(PatientsFiltersCleared()),
      onInputChanged(input: keyof PatientsFilters, value) {
        sink.broadcast(PatientsFilterChanged({ input, value }));
      },
    };
  },
});
