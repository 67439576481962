














import { ListPageLayout, InlineLoader } from '@/shared';
import sink from '@/sink';
import { defineComponent } from '@vue/composition-api';
import Actions from './Actions.vue';
import Filters from './Filters.vue';
import Table from './Table.vue';

export default defineComponent({
  components: { ListPageLayout, Actions, Filters, Table, InlineLoader },
  setup() {
    return {
      loading: sink.select('patients.loading'),
    };
  },
});
