






















































































































































import PreferredLanguageFilter from '@/shared/components/PreferredLanguageFilter.vue';
import { PatientCreatePayload } from '@/patients/models';
import router from '@/router';
import {
  defaultAlertMessage,
  InlineLoader,
  CheckboxInput,
  Alert,
} from '@/shared';
import DatePicker from '@/shared/components/DatePicker.vue';
import { genderOptions } from '@/shared/gender';
import sink from '@/sink';
import { userModule } from '@/user';
import { DialogInstance } from '@conversa/bedazzled/src/dialog';
import DialogTitle from '@/dialog/DialogTitle.vue';

import PreferredCommunication from '@/shared/components/PreferredCommunication.vue';

import { PreferredCommunicationEventPayload } from '@conversa/bedazzled/src/inputs/PreferredCommunication/preferred-communication-event.interface';
import {
  defineComponent,
  onUnmounted,
  reactive,
  ref,
} from '@vue/composition-api';
import format from 'date-fns/format';
import subYears from 'date-fns/subYears';
import { ofType } from 'ts-action-operators';
import {
  AddPatientDialogSave,
  AddPatientDialogSaveBeforeEnroll,
  NewPatientFailed,
  NewPatientInvalid,
  NewPatientSaved,
  NewPatientSavedBeforeEnroll,
} from '../+state/events';
import EnrollmentProgramListDialog from '../detail/summary/enrollments/dialogs/EnrollmentProgramListDialog.vue';

/*
 * When we get API validation errors the API sends back a field and a message, this
 * maps the programmatic field name from the API to a name that matches the UI
 */
const validationFieldMap = {
  userId: 'Provider',
  clinicPatientCode: 'Patient ID',
  firstName: 'First Name',
  lastName: 'Last Name',
  mobilePhone: 'Phone',
  email: 'Email',
  preferredLanguage: 'Preferred Language',
  dateOfBirth: 'Date of Birth',
  gender: 'Gender',
};

export default defineComponent({
  components: {
    Alert,
    CheckboxInput,
    DatePicker,
    InlineLoader,
    PreferredCommunication,
    PreferredLanguageFilter,
    DialogTitle,
  },
  setup() {
    let shouldGoToEnroll = false;
    const formRef = ref();
    const inputs: PatientCreatePayload = reactive({
      userId: userModule.injectStore().id,
      clinicPatientCode: null,
      firstName: null,
      lastName: null,
      mobilePhone: null,
      email: null,
      preferredLanguage: 'en',
      dateOfBirth: '',
      gender: null,
      organizationalUnitId: null,
      state: null,
    });

    const patientConsent = ref(false);
    const alertMessage = ref(null);

    const resetAlert = () => {
      alertMessage.value = null;
    };

    const newPatientSaved$ = sink.events$
      .pipe(ofType(NewPatientSaved))
      .subscribe(event => {
        router.push(`/patients/${event.payload.id}`);
      });

    const newPatientSavedBeforeEnroll$ = sink.events$
      .pipe(ofType(NewPatientSavedBeforeEnroll))
      .subscribe(() => {
        DialogInstance.open(EnrollmentProgramListDialog);
      });

    const newPatientFailed$ = sink.events$
      .pipe(ofType(NewPatientFailed))
      .subscribe(() => {
        alertMessage.value = defaultAlertMessage;
      });

    const newPatientInvalid$ = sink.events$
      .pipe(ofType(NewPatientInvalid))
      .subscribe(event => {
        if (event.payload.errors?.length) {
          const firstError = event.payload.errors[0];
          alertMessage.value = `${validationFieldMap[firstError.field]} ${
            firstError.message
          }`;
        } else {
          alertMessage.value = defaultAlertMessage;
        }
      });

    onUnmounted(() => {
      newPatientInvalid$.unsubscribe();
      newPatientFailed$.unsubscribe();
      newPatientSaved$.unsubscribe();
      newPatientSavedBeforeEnroll$.unsubscribe();
    });

    const today = new Date();
    const minISODate = format(subYears(today, 130), 'yyyy-MM-dd');
    const maxISODate = format(today, 'yyyy-MM-dd');

    const isFormValid = ref(false);
    const sms = sink.select('capabilities.features.sms');

    return {
      sms,
      isFormValid,
      loadingProviders: sink.select('providers.loading'),
      isLoadingPatient: sink.select('patients.loading'),
      providers: sink.select('providers.select-options'),
      demographicCapabilities: sink.select('capabilities.demographics'),
      minISODate,
      maxISODate,
      genderOptions,
      stateOptions: sink.select('patients.detail.state.select-options'),
      formRef,
      inputs,
      alertMessage,
      patientConsent,
      languageItems: sink.select('capabilities.enabledLanguages.selectOptions'),
      canSeeLanguageSelector: sink.select(
        'capabilities.enabledLanguages.hasMultiple',
      ),
      selectedOrg: sink.select('organizations.selected'),
      cancel: () => {
        resetAlert();
        DialogInstance.close();
      },
      saveAndEnroll() {
        shouldGoToEnroll = true;
        formRef.value.$el.requestSubmit();
      },
      save() {
        resetAlert();

        let gender = inputs.gender;

        /*
         * Normally the validation would catch when gender is empty and carp, but when the demographic settings
         * for the org have gender disabled, we want to make sure that we pass back null for any blankish values
         */
        if (!this.demographicCapabilities?.genderEnabled && !gender?.length) {
          gender = null;
        }

        // same for state
        let state = inputs.state;
        if (!this.demographicCapabilities?.stateEnabled && !state?.length) {
          state = null;
        }

        const inputsWithHidden = {
          ...inputs,
          preferredCommunicationChannel: !sms.value
            ? 'email'
            : inputs.preferredCommunicationChannel,
          organizationalUnitId: this.selectedOrg.id,
          userId: inputs.userId,
          gender,
          dateOfBirth:
            (inputs.dateOfBirth && inputs.dateOfBirth.substring(0, 10)) || null,
          state,
        };

        if (shouldGoToEnroll) {
          sink.broadcast(AddPatientDialogSaveBeforeEnroll(inputsWithHidden));
          return;
        }

        sink.broadcast(AddPatientDialogSave(inputsWithHidden));
      },
      commValuesUpdated(values: PreferredCommunicationEventPayload) {
        inputs.email = values.email;
        inputs.mobilePhone = values.phone;
        inputs.preferredCommunicationChannel = values.preferred;
      },
    };
  },
});
