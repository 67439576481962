








import { DialogInstance } from '@conversa/bedazzled/src/dialog';
import { defineComponent } from '@vue/composition-api';
import AddPatientDialogVue from './dialogs/AddPatientDialog.vue';

export default defineComponent({
  setup() {
    return {
      addPatient: () => DialogInstance.open(AddPatientDialogVue),
    };
  },
});
