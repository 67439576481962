



























































import { defineComponent } from '@vue/composition-api';
import { PatientsPageUpdated, PatientsTableSorted } from './+state/events';
import { PatientSortCol, SortDir } from '@/shared';
import sink from '@/sink';

export default defineComponent({
  setup() {
    const sortCol = sink.select('patients.sort-col');
    const sortDir = sink.select('patients.sort-dir');

    const updateSort = (sortInfo: { col: PatientSortCol; dir: SortDir }) =>
      sink.broadcast(PatientsTableSorted(sortInfo));

    const onChangeSortColumn = (col: PatientSortCol) => {
      updateSort({ col, dir: sortDir.value });
    };

    const onChangeSortDirection = (isDesc: boolean) => {
      updateSort({ col: sortCol.value, dir: isDesc ? 'desc' : 'asc' });
    };

    return {
      onChangeSortColumn,
      onChangeSortDirection,
      updatePage: page => {
        sink.broadcast(PatientsPageUpdated({ page }));
      },
      sortCol,
      sortDir,
      patients: sink.select('patients.table'),
      count: sink.select('patients.count'),
      pageNumber: sink.select('patients.page-number'),
      filtersDirty: sink.select('patients.filters-dirty'),
      tableMessage: sink.select('patients.tableMessage'),
      headers: sink.select('patients.tableHeaders'),
    };
  },
});
